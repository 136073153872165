'use client';

import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';

export default function MenubarComponent({ items }: { items: MenuItem[] }) {
  return (
    <Menubar
      model={items}
      pt={{
        root: {
          className: 'bg-transparent border-none max-lg:hidden',
        },
        content: {
          className: 'hover:bg-transparent bg-transparent p-0',
        },
        action: {
          className: 'p-2',
        },
        menu: {
          className: 'gap-6',
        },
      }}
    />
  );
}
