'use client';

import { Link } from '@/i18n/routing';
import { useAuthStore } from '@/store/features/auth';
import { Message } from 'primereact/message';
import { useEffect } from 'react';

const HeaderComponent = () => {
  const { isAuthenticated, checkAuth, isLoading } = useAuthStore();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const content = (
    <div className="flex items-center gap-1 ml-2 group max-sm:text-xs flex-wrap">
      Oyuna giriş yapabilmek için önce siteden{' '}
      <b>
        <Link
          href="/auth/register"
          className="hover:underline group-hover:text-emerald-500 w-max"
        >
          Kayıt
        </Link>
      </b>{' '}
      olmanız gerekmektedir.
    </div>
  );

  return isLoading ? (
    <Message
      severity="info"
      content="Loading..."
      pt={{
        root: { className: 'w-full' },
      }}
    />
  ) : !isAuthenticated ? (
    <Message
      // style={{
      //   border: 'solid #696cff',
      //   borderWidth: '0 0 0 8px',
      //   color: '#696cff',
      // }}
      pt={{
        root: { className: 'w-full' },
      }}
      severity="info"
      content={content}
    />
  ) : (
    <Message
      severity="success"
      content="Birden fazla oyun seçeneği ile eğlencenin tadını çıkarın!"
      pt={{
        root: { className: 'w-full' },
      }}
    />
  );
};

export default HeaderComponent;
