'use client';

import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <button
      className={`fixed bottom-10 right-10 z-50 p-2 rounded-full animate-bell-ringer hover:animate-none bg-gray-600 hover:bg-gray-500 text-white hover:text-slate-200 group transition-all duration-500 ${isVisible ? 'block' : 'hidden'}`}
      onClick={scrollToTop}
    >
      <Icon icon="ph:mouse-simple-light" width={25} height={25} />
    </button>
  );
};

export default ScrollToTopButton;
