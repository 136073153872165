import axios from 'axios';

export function getErrorMessage(error: unknown): string {
  if (axios.isAxiosError(error)) {
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message;
    }
    // Axios hatası ama response yoksa veya mesaj yoksa generic bir mesaj döndür
    return error.message || 'Beklenmeyen bir hata oluştu';
  }

  // Normal bir JS hatası ise, error instanceof Error kontrolleri
  if (error instanceof Error) {
    return error.message;
  }

  return 'Bilinmeyen bir hata oluştu';
}
