import { persist, createJSONStorage } from 'zustand/middleware';
import { getUserAction } from '@/actions/user/getUser';
import { create } from 'zustand';
import { IUser } from '@/index';

interface State {
  data: IUser | null;
  isLoading: boolean;
  error: string | null;
  fetchData: () => Promise<void>;
}

export const useUserStore = create(
  persist<State>(
    (set) => ({
      data: null,
      isLoading: true,
      error: null,
      fetchData: async () => {
        set({ isLoading: true, error: null });

        const user = await getUserAction();

        if (user.error) {
          set({ error: user.error.message as string, isLoading: false });
          return;
        }

        set({ data: user.data, isLoading: false });
      },
    }),
    {
      name: 'user',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
