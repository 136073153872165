const FooterComponent = () => {
  return (
    <footer className="flex items-center justify-center w-full h-24 border-t">
      <section className="container flex justify-center items-center">
        <p className="text-sm text-center text-gray-500">
          © 2024 CacheMine. Tüm hakları saklıdır.
        </p>
      </section>
    </footer>
  );
};

export default FooterComponent;
