'use client';

import { Link } from '@/i18n/routing';
import { Icon } from '@iconify/react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
// import { toast } from 'react-toastify';
import { useUserStore } from '@/store/features/user';
import { useAuthStore } from '@/store/features/auth';
import useMediaQuery from '@/hooks/useMediaQuery';

const ProfileComponent = () => {
  const [open, setOpen] = useState(false);

  const { isMobile } = useMediaQuery();

  const { data, fetchData, isLoading: isUserLoading, error } = useUserStore();
  const {
    isAuthenticated,
    checkAuth,
    isLoading: isAuthLoading,
  } = useAuthStore();

  // if (error && isAuthenticated) {
  //   toast.error(error);
  // }

  const pathname = usePathname();

  const menuRight = useRef<Menu>(null);

  useEffect(() => {
    if (!data && !error) {
      fetchData().catch((err) => {
        console.error('Kullanıcı verisi alınırken hata:', err.message);
      });
    }
  }, [data, fetchData, error]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
    document.body.style.paddingRight = !isMobile && open ? '10px' : '0';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0';
    };
  }, [open]);

  const items: MenuItem[] = [
    {
      label: isUserLoading
        ? 'Loading...'
        : data?.nickname?.toUpperCase() || 'Kshup',
      items: [
        {
          template: () => {
            return (
              <Link
                href="/profile/plan"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="tdesign:user-vip-filled"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
                <span className="group-hover:text-emerald-500">Planlarım</span>
              </Link>
            );
          },
        },
        {
          template: () => {
            return (
              <Link
                href="/profile/notification"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="streamline:ringing-bell-notification-solid"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
                <span className="group-hover:text-emerald-500">
                  Bildirimler
                </span>
              </Link>
            );
          },
        },
        {
          template: () => {
            return (
              <Link
                href="/profile/message"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="flowbite:messages-solid"
                  className="group-hover:text-emerald-500"
                  width={25}
                />
                <span className="group-hover:text-emerald-500">Mesajlar</span>
              </Link>
            );
          },
        },
        {
          label: 'Ayarlar',
          template: () => {
            return (
              <Link
                href="/profile/setting"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="fluent:settings-20-filled"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
                <span className="group-hover:text-emerald-500">Ayarlar</span>
              </Link>
            );
          },
        },
        {
          label: 'Arkadaşlarım',
          template: () => {
            return (
              <Link
                href="/profile/friend"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="gridicons:multiple-users"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
                <span className="group-hover:text-emerald-500">
                  Arkadaşlarım
                </span>
              </Link>
            );
          },
        },
        {
          separator: true,
          className: '!h-3 invisible',
        },
        {
          template: () => {
            return (
              <Link
                href="/auth/logout"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="solar:logout-3-bold"
                  className="group-hover:text-emerald-900"
                  width={22}
                />
                <span className="group-hover:text-emerald-900">Çıkış Yap</span>
              </Link>
            );
          },
        },
      ],
    },
  ];

  return (
    <section className="flex justify-center items-center h-full">
      {isAuthLoading ? (
        <Button
          label="Loading..."
          icon={<Icon icon="mdi:loading" className="animate-spin" width={22} />}
          disabled
          pt={{
            root: {
              className:
                'gap-2 bg-transparent text-black border-black group p-2',
            },
          }}
        />
      ) : isAuthenticated ? (
        <Button
          icon={
            <Icon
              icon="game-icons:ram-profile"
              className="group-hover:text-emerald-500"
              width={22}
            />
          }
          onClick={(event) => menuRight.current?.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
          pt={{
            root: {
              className:
                'gap-2 bg-transparent text-black border-black hover:border-emerald-500 group p-2',
            },
          }}
        >
          <span className="group-hover:text-emerald-500">Profil</span>
        </Button>
      ) : (
        <div className="flex gap-2">
          <Link href={`/auth/login?from=${encodeURIComponent(pathname)}`}>
            <Button
              icon={
                <Icon
                  icon="solar:login-3-bold"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
              }
              pt={{
                root: {
                  className:
                    'gap-2 text-black border-blue-300 hover:border-black hover:border-emerald-500 group p-2 bg-green-500 hover:bg-transparent text-white',
                },
              }}
            >
              <span className="group-hover:text-emerald-500">Giriş Yap</span>
            </Button>
          </Link>
          <Link href="/auth/register">
            <Button
              icon={
                <Icon
                  icon="mdi:register"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
              }
              pt={{
                root: {
                  className:
                    'gap-2 bg-transparent text-black border-black hover:border-emerald-500 group p-2',
                },
              }}
            >
              <span className="group-hover:text-emerald-500">Kayıt Ol</span>
            </Button>
          </Link>
        </div>
      )}
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
        pt={{
          root: {
            className: 'p-0 rounded-md mt-[26px]',
          },
          submenuHeader: {
            className:
              'bg-primary/90 text-white first:rounded-t-md last:bg-red-500/90',
          },
          menuitem: {
            className:
              'flex items-center px-4 h-10 not:last:hover:bg-slate-200 cursor-pointer group last:bg-red-500/90 last:hover:bg-red-400 last:text-white',
          },
        }}
        onShow={() => setOpen(true)}
        onHide={() => setOpen(false)}
      />
    </section>
  );
};

export default ProfileComponent;
