import { getErrorMessage } from '@/lib/getErrorMessage';
import { isAuth } from '@/lib/jwt';
import { create } from 'zustand';

interface LoginState {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  resetModal: () => void;
}

interface RegisterState {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  resetModal: () => void;
}

interface ForgotPasswordState {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  resetModal: () => void;
}

interface AuthState {
  isAuthenticated: boolean;
  setIsAuthenticated: (status: boolean) => void;
  checkAuth: () => Promise<void>;
  isLoading: boolean;
  error: string | null;
}

const initialLoginState: LoginState = {
  isOpenModal: true,
} as LoginState;

const initialRegisterState: RegisterState = {
  isOpenModal: true,
} as RegisterState;

const initialForgotPasswordState: ForgotPasswordState = {
  isOpenModal: true,
} as ForgotPasswordState;

export const useLoginStore = create<LoginState>((set) => ({
  ...initialLoginState,
  isOpenModal: true,
  setIsOpenModal: (isOpenModal) => set({ isOpenModal }),
  resetModal: () => set(initialLoginState),
}));

export const useRegisterStore = create<RegisterState>((set) => ({
  ...initialRegisterState,
  setIsOpenModal: (isOpenModal) => set({ isOpenModal }),
  resetModal: () => set(initialRegisterState),
}));

export const useForgotPasswordStore = create<ForgotPasswordState>((set) => ({
  ...initialForgotPasswordState,
  setIsOpenModal: (isOpenModal) => set({ isOpenModal }),
  resetModal: () => set(initialForgotPasswordState),
}));

export const useAuthStore = create<AuthState>((set) => ({
  isAuthenticated: false,
  isLoading: true,
  error: null,
  setIsAuthenticated: (status) => set({ isAuthenticated: status }),
  checkAuth: async () => {
    set({ isLoading: true, error: null });
    try {
      const data = await isAuth();
      set({ isAuthenticated: data, isLoading: false });
    } catch (err) {
      const message = getErrorMessage(err);
      set({ error: message, isLoading: false });
    }
  },
}));
